import React from 'react'
import Layout from '../components/layout'
import Intro from '../components/intro'
import Header from '../components/header'
import Quote from '../components/quote'
import Information from '../components/information'
import Adventure from '../components/adventure'
import Booking from '../components/booking'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout>
    <Header
      background={data.route.frontmatter.image.childImageSharp.fixed}
      title={data.route.frontmatter.heroTitle}
      seoTitle={data.route.frontmatter.seoTitle}
      seoDescription={data.route.frontmatter.seoDescription}
    />
    <Intro description={data.route.frontmatter.introText} />
    <Quote
      photo={data.route.frontmatter.quoteImage1.childImageSharp.fluid}
      text={data.route.frontmatter.quoteText1}
    />
    {data.route.frontmatter.tips.filter((tip) => tip.image).length > 0 && (
      <Adventure
        background="road"
        itineraries={data.route.frontmatter.tips.filter((tip) => tip.image)}
      >
        <h2>{data.route.frontmatter.tipsTitle}</h2>
        {data.route.frontmatter.tipsDescription.split("\n\n").map((text, index) => <p key={index}>{text}</p>)}
      </Adventure>
    )}
    <Quote
      dark
      withMargin={true}
      photo={data.route.frontmatter.quoteImage2.childImageSharp.fluid}
      text={data.route.frontmatter.quoteText2}
    />
    <Information />
    <Quote
      photo={data.route.frontmatter.quoteImage3.childImageSharp.fluid}
      text={data.route.frontmatter.quoteText3}
    />
    <Booking />
  </Layout>
)

export const query = graphql`
  query RouteByURL($url: String!) {
    route: markdownRemark(frontmatter: { url: { eq: $url } }) {
      frontmatter {
        seoTitle
        seoDescription
        heroTitle
        image {
          childImageSharp {
            fixed(width: 1600, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        introText
        tipsTitle
        tipsDescription
        tips {
          image {
            childImageSharp {
              fixed(width: 720, height: 480, quality: 80) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          description
          title
        }
        quoteImage1 {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quoteText1
        quoteImage2 {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quoteText2
        quoteImage3 {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        quoteText3
      }
    }
  }
`
